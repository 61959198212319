import jquery from "jquery";
window.$ = window.jQuery = jquery;

import 'lazysizes';

import Modal from "bootstrap/js/dist/modal";
Array.from(document.querySelectorAll('.modal'))
    .forEach(modalNode => new Modal(modalNode));

import Aos from "aos";

Aos.init();